import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import Layout from "~/components/Layout";
import { Colors } from "~/components/ThemeVariables";
import PrimaryButton from "~/components/common/PrimaryButton";
import { breakpointIpad } from "~/components/styleUtils";
import ExternalLink from "~/components/common/ExternalLink";

const CenterElements = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 40px;

    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;

    ${breakpointIpad(`
        padding-left: 40px;
        padding-right: 40px;
    `)}
`;

const Figure = styled.figure`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 328px;
    ${breakpointIpad(`
        height: 280px;
    `)}
    max-width: 1232px;
    width: 80%;

    padding: 10px 10px 0 10px;

    background: radial-gradient(
        90.16% 143.01% at 15.32% 21.04%,
        rgba(165, 239, 255, 0.2) 0%,
        rgba(110, 191, 244, 0.0447917) 77.08%,
        rgba(70, 144, 213, 0) 100%
    );

    border: 1px solid #bfe0ff;
    border-radius: 10px;
`;

const AegisLogoSVG = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 308 94"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M57.1856 69.7642C52.2529 74.7766 46.0815 79.0574 40.2507 81.5056C40.0189 81.607 39.7074 81.6143 39.4394 81.4984C39.4394 81.4984 39.4322 81.4984 39.4249 81.4984C24.3008 74.581 7.03275 60.3189 3.93984 41.0082C3.90363 40.7764 3.93984 40.5518 4.05574 40.3563C11.2121 27.2169 21.5629 22.8419 33.9997 27.6949C34.485 27.8832 34.7675 28.4265 34.6081 28.9553C30.8416 41.0878 31.7615 51.7935 37.2012 59.0948C41.5545 64.9401 48.3922 68.1127 56.454 68.0258C57.3667 68.0185 57.8375 69.1195 57.2001 69.7714L57.1856 69.7642ZM21.2804 22.4363C30.3273 21.3787 40.0551 25.9275 48.6819 35.257C48.6819 35.257 48.6891 35.257 48.6964 35.2642C49.0658 35.6553 49.667 35.6843 50.0799 35.3584C56.4178 30.2808 59.4455 24.2326 59.0833 17.3659C58.8443 12.933 57.1638 8.13068 54.0854 3.08932C53.9695 2.89375 53.7884 2.74889 53.5784 2.66197C35.3035 -4.29887 14.3123 3.51668 0.760021 13.1576C0.535478 13.3169 0.376125 13.5777 0.332665 13.8674C-0.000528575 16.5185 -0.0802052 19.3796 0.0791481 22.3059C0.296448 26.3332 0.955591 30.4908 2.04209 34.4095C2.28836 35.2859 3.47627 35.438 3.92536 34.6413C7.88022 27.6152 14.0443 23.2837 21.2876 22.4363H21.2804ZM79.495 16.8734C79.4009 15.0625 79.2705 13.8312 79.2705 13.8312C79.2415 13.5559 79.0822 13.2807 78.8721 13.1286C73.8307 9.3041 67.0365 6.0953 60.235 4.32793C59.3513 4.09615 58.627 5.05951 59.0906 5.84179C63.1975 12.7809 63.8639 20.285 60.9811 26.9778C57.3739 35.3439 48.566 41.4862 36.1799 44.2966C36.1799 44.2966 36.1799 44.2966 36.1727 44.2966C35.6729 44.4125 35.3107 44.9051 35.3976 45.4483C37.4113 58.6457 46.3858 65.4182 61.336 65.0488C61.5605 65.0488 61.7851 64.9619 61.9589 64.817C79.8355 50.2869 79.9079 24.4427 79.5023 16.8661L79.495 16.8734Z"
            fill="url(#paint0_linear_1_24580)"
        />
        <path
            d="M130.663 60.7148H107.706L103.761 71.9143H94.3719L114.013 17.0068H124.428L144.069 71.9143H134.602L130.657 60.7148H130.663ZM119.224 27.8948L110.23 53.3761H128.139L119.224 27.8948Z"
            fill="white"
        />
        <path
            d="M171.289 72.6281C158.824 72.6281 149.597 63.7904 149.597 50.1446C149.597 36.4989 158.435 27.7391 171.289 27.7391C184.143 27.7391 192.429 36.3366 192.429 49.1194C192.429 50.6183 192.352 52.0393 192.112 53.5382H158.896C159.525 60.7148 164.658 65.0557 171.283 65.0557C176.805 65.0557 179.88 62.3759 181.541 58.9823H191.242C188.796 66.5546 181.931 72.6281 171.283 72.6281H171.289ZM158.98 46.2774H183.04C182.885 39.5745 177.596 35.3114 170.893 35.3114C164.82 35.3114 159.927 39.4123 158.98 46.2774Z"
            fill="white"
        />
        <path
            d="M218.774 27.7391C225.873 27.7391 231.161 31.0548 233.841 34.7599V28.4464H242.912V72.6281C242.912 84.4635 235.262 93.379 221.688 93.379C210.092 93.379 201.495 87.617 200.385 77.9942H209.301C210.644 82.4909 215.452 85.5666 221.688 85.5666C228.63 85.5666 233.834 81.3035 233.834 72.6281V65.3672C231.07 69.0787 225.866 72.6281 218.768 72.6281C207.328 72.6281 198.257 63.3946 198.257 49.9889C198.257 36.5832 207.328 27.7391 218.768 27.7391H218.774ZM220.669 35.5515C213.966 35.5515 207.497 40.5997 207.497 49.9889C207.497 59.3781 213.966 64.8221 220.669 64.8221C227.372 64.8221 233.841 59.5338 233.841 50.1511C233.841 40.7684 227.449 35.558 220.669 35.558V35.5515Z"
            fill="white"
        />
        <path
            d="M253.558 16.9289C253.558 13.6975 256.083 11.1734 259.32 11.1734C262.558 11.1734 264.998 13.6975 264.998 16.9289C264.998 20.1603 262.474 22.6909 259.32 22.6909C256.167 22.6909 253.558 20.1668 253.558 16.9289ZM254.739 28.4464H263.733V71.9143H254.739V28.4464Z"
            fill="white"
        />
        <path
            d="M291.35 72.6281C280.936 72.6281 273.597 66.4768 273.207 58.5865H282.519C282.837 62.1358 286.224 65.0557 291.194 65.0557C296.165 65.0557 299.162 62.8496 299.162 59.8518C299.162 51.3321 273.915 56.2246 273.915 40.2883C273.915 33.3453 280.384 27.7456 290.643 27.7456C300.901 27.7456 306.975 33.0339 307.448 41.7093H298.455C298.137 37.9199 295.224 35.3179 290.331 35.3179C285.439 35.3179 282.992 37.2905 282.992 40.2104C282.992 48.9637 307.526 44.0777 308 59.7739C308 67.1127 301.609 72.6346 291.356 72.6346L291.35 72.6281Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1_24580"
                x1="16.6591"
                y1="0.452751"
                x2="74.7935"
                y2="98.8012"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7991FF" />
                <stop offset="1" stopColor="#6962D1" />
            </linearGradient>
        </defs>
    </svg>
);

const AegisLogoContainer = styled.div`
    max-width: 277px;
    margin-bottom: 12px;

    ${breakpointIpad(`
        max-width: 140px;
    `)}
`;

const Figcaption = styled.figcaption`
    font-weight: 300;
    font-size: 28px;
    text-align: center;

    ${breakpointIpad(`
        font-size: 22px;
    `)}

    color: ${Colors.WHITE};

    margin-bottom: 23px;
`;

const PrimaryButtonRecolored = styled(PrimaryButton)`
    font-size: 16px;
    color: #94a3ff;

    background-color: rgba(148, 163, 255, 0.2);

    border-color: #94a3ff;
    border-width: 2px;

    :hover {
        border-radius: 0px;
        background: #94a3ff;
        color: ${Colors.WHITE};
    }
`;

const ProductLogoHighlight = () => (
    <Figure>
        <AegisLogoContainer>
            <AegisLogoSVG />
        </AegisLogoContainer>
        <Figcaption>Climate Insight, Financial Foresight</Figcaption>
        <ExternalLink to="https://aegis.dclimate.net/signup">
            <PrimaryButtonRecolored>Launch Aegis App</PrimaryButtonRecolored>
        </ExternalLink>
    </Figure>
);

const P = styled.p`
    font-size: 18px;

    color: ${Colors.WHITE};

    max-width: 700px;
`;

const ImageContainer = styled.div`
    max-width: 900px;
`;

const PerilContainer = styled.div`
    ${breakpointIpad(`
        width: 90%;
    `)}
`;

const H2 = styled.h2`
    font-size: 36px;
    font-weight: 600;

    color: ${Colors.WHITE};

    margin-top: 40px;
`;

// const FeatureContainer = styled.section`
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     width: 100%;
//     max-width: 900px;

//     ${breakpointIpad(`
//         width: 540px;
//     `)}
// `;

// interface FeatureProps {
//     staticImg: JSX.Element;
// }
// /* Gatsby has to know static image sources at build time, so we pass in an entire <StaticImage> component instead of building it here */
// const Feature = ({ staticImg }: FeatureProps) => (
//     <FeatureContainer>{staticImg}</FeatureContainer>
// );

const ReportButton = styled(PrimaryButton)`
    font-weight: 600;
    color: #343a5f;
    background-color: #94a3ff;

    width: 180px;
    padding: 14px 0;
    border-radius: 8px;
    border: none;

    :hover {
        background-color: #94a3ff;
        border-radius: 6px;
        color: ${Colors.WHITE};
    }
`;

const ContactButton = styled(PrimaryButton)`
    font-weight: 600;
    color: #fff;
    background-color: none;
    text-decoration: none;

    width: 180px;
    padding: 12px 0;
    border-radius: 8px;
    border: 2px solid white;

    :hover {
        background-color: #fff;
        border-radius: 6px;
        color: #343a5f;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const Aegis = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - Aegis</title>

            <meta
                name="description"
                content="The market leading platform for physical climate risk assessment."
            />

            <meta property="og:title" content="dClimate - Aegis" />
            <meta property="og:type" content="website" />

            <meta
                property="og:url"
                content="https://dclimate.net/products/aegis"
            />
            <meta
                name="og:description"
                content="The market leading platform for physical climate risk assessment."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/aegis/1200x630.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
                property="og:image:alt"
                content="Shield with Aegis word next to graphic of natural disasters."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/aegis/1920x960.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="960" />
            <meta
                property="og:image:alt"
                content="Shield with Aegis word next to graphic of natural disasters."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/aegis/1920x1080.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            <meta
                property="og:image:alt"
                content="Shield with Aegis word next to graphic of natural disasters."
            />

            <meta property="og:site_name" content="dClimate" />

            <meta
                property="og:video"
                content="https://www.youtube.com/watch?v=nHOXUVYwWL4"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="dClimate - Aegis" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:image"
                content="https://dclimate.net/img/meta/aegis/1200x630.png"
            />
            <meta
                name="twitter:image:alt"
                content="Shield with Aegis word next to graphic of natural disasters."
            />
        </Helmet>
        <Layout gradientOnlyBackground>
            <CenterElements>
                <ProductLogoHighlight />
                <P>
                    Climate change is not a far-off problem, it&apos;s a
                    present-day reality. As the environment around us shifts, so
                    does the potential risk to our homes, communities, and
                    businesses. Understanding these risks is vital, yet often
                    complex. That&apos;s where we come in. Introducing Aegis, a
                    revolutionary physical climate risk assessment tool designed
                    to demystify and quantify the potential impacts of climate
                    change on your doorstep.
                </P>
                <ImageContainer>
                    <StaticImage
                        src="../../components/products/aegis/asess-risk.png"
                        alt="Assess risk form screenshot"
                        placeholder="blurred"
                    />
                </ImageContainer>
                <P>
                    Simply enter your location of interest, select from a range
                    of climate perils, and let our powerful algorithm do the
                    rest. Whether it&apos;s a tropical cyclone, thunderstorm
                    wind, or hail, our app will show you the history of events
                    at your location, the projected frequency and magnitude of
                    events going forwards, and calculate the financial risk to
                    your physical assets - helping you make more informed
                    decisions for the future.
                </P>
                <H2>Comprehensive Peril Coverage</H2>
                <P>
                    Aegis provides a single, comprehensive solution for
                    assessing 14 different climate perils at up to 90m
                    resolution, globally.
                </P>
                <PerilContainer>
                    <ImageContainer>
                        <StaticImage
                            src="../../components/products/aegis/aegis-perils.png"
                            alt="Assess risk form screenshot"
                            placeholder="blurred"
                        />
                    </ImageContainer>
                </PerilContainer>

                <H2>Quantitative Risk Solutions</H2>
                <P>
                    Aegis quantitatively demonstrates how hazard risk and
                    financial risk evolve over time. Quantitative outputs (from
                    exceedance probabilities to projected frequency, to
                    financial loss estimates) can be seamlessly integrated into
                    insurance strategies, regulatory disclosures, and effective
                    risk mitigation and adaptation plans.
                </P>

                <H2>Easy to Understand, Auditable Data You Can Trust</H2>
                <P>
                    Leverage dClimate&apos;s extensive, auditable, historical
                    data, global climate models, and in house expertise to
                    perform hyper-local hazard modeling and vulnerability
                    modeling. Our app takes complex climate data and simplifies
                    it into easy-to-understand terms. You don&apos;t need to be
                    a scientist to understand your risk.
                </P>

                <H2>Portfolio-Level Analysis</H2>
                <ImageContainer>
                    <StaticImage
                        src="../../components/products/aegis/aegis-portfolio.png"
                        alt="Porfolio view displaying locations from assessment in a list view and pinpointed on a map"
                        placeholder="blurred"
                    />
                </ImageContainer>
                <P>
                    Aegis identifies which locations in your portfolio, supply
                    chain, distribution footprint, etc. are at highest risk and
                    require attention. Quickly evaluate your portfolio&apos;s
                    exposure to a single climate event or combination of climate
                    events under different climate scenarios and time periods.
                </P>

                <H2>Asset-Level Analysis</H2>
                <ImageContainer>
                    <StaticImage
                        src="../../components/products/aegis/summary-chart.png"
                        alt="Porfolio view displaying locations from assessment in a list view and pinpointed on a map"
                        placeholder="blurred"
                    />
                </ImageContainer>
                <P>
                    See which perils your asset is most vulnerable to with our
                    Average Annual Loss (AAL) estimates, then dive into the
                    underlying drivers of climate risk by analyzing historical
                    and projected frequency of events, as well as the
                    probability of severe events at your location.
                </P>
                <ImageContainer>
                    <StaticImage
                        src="../../components/products/aegis/cyclone-chart.png"
                        alt="Porfolio view displaying locations from assessment in a list view and pinpointed on a map"
                        placeholder="blurred"
                    />
                </ImageContainer>
                <P>
                    Understanding the potential climate risks for your assets is
                    essential for effective planning and decision making. By
                    taking appropriate risk management measures, you can
                    mitigate the impacts of these climate perils.
                </P>

                <ButtonContainer>
                    {" "}
                    <ExternalLink to="https://aegis.dclimate.net/signup">
                        <ReportButton>Sign Up</ReportButton>
                    </ExternalLink>
                    <ExternalLink to="https://form.typeform.com/to/gr26YCfm">
                        <ContactButton>Contact Us</ContactButton>
                    </ExternalLink>
                </ButtonContainer>

                {/* <ReportContainer>
                    <ReportImageContainer>
                        <StaticImage
                            src="../../components/products/aegis/pricing.png"
                            alt="Aegis report with graph of model results"
                            placeholder="blurred"
                        />
                    </ReportImageContainer>
                    <ReportTextAndButton>
                        <H2>Go to Aegis and get up and running in minutes!</H2>
                        <P2>
                            Understanding the potential climate risks for your
                            assets is essential for effective planning and
                            decision making. By taking appropriate risk
                            management measures, you can mitigate the impacts of
                            these climate perils.
                        </P2>
                        <ExternalLink to="https://csti51zef2d.typeform.com/Aegis">
                            <ReportButton>Contact Us</ReportButton>
                        </ExternalLink>
                    </ReportTextAndButton>
                </ReportContainer> */}
            </CenterElements>
        </Layout>
    </>
);

export default Aegis;
